<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="门店编号">
        <el-input
          size="mini"
          v-model="formInline.storeNo"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input
          size="mini"
          v-model="formInline.phone"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="昵称">
        <el-input
          size="mini"
          v-model="formInline.nickname"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="dialogVisible = true"
          >新增门店</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#f8f8f9',
        color: '#515a6e',
        fontSize: '13px',
        textAlign: 'center'
      }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="storeNo" label="门店编号"> </el-table-column>
      <el-table-column prop="nickname" label="昵称"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="account" label="账号"> </el-table-column>
      <el-table-column prop="passwd" label="密码"> </el-table-column>
      <el-table-column prop="walletBalance" label="门店余额(元)">
      </el-table-column>
      <el-table-column prop="wechatNo" label="客服微信号"> </el-table-column>
      <el-table-column prop="wechatImg" label="客服微信二维码">
        <template slot-scope="{ row }">
          <el-image
            v-if="row.wechatImg"
            style="width: 50px; height: 50px"
            :src="row.wechatImg"
            :preview-src-list="[row.wechatImg]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="edit(row)"
            >编辑</el-button
          >
          <el-button type="primary" size="mini" @click="goDetails(row)"
            >资金明细</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="fy">
      <div></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="ruleForm.id ? '编辑' : '新增'"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="客服二维码">
          <el-upload
            class="avatar-uploader"
            action
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.wechatImg"
              :src="ruleForm.wechatImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action
            :show-file-list="false"
            :before-upload="beforeAvatarUpload1"
          >
            <img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input v-model="ruleForm.account"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="passwd">
          <el-input v-model="ruleForm.passwd"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="ruleForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="客服微信号">
          <el-input v-model="ruleForm.wechatNo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSubStorePage,
  insertSubStore,
  file,
  updateSubStore
} from '../../../api/user'
import axios from 'axios'
export default {
  data() {
    return {
      ruleForm: {
        account: '',
        passwd: '',
        phone: '',
        avatar: '',
        nickname: '',
        wechatNo: '',
        wechatImg: ''
      },
      rules: {
        account: [{ required: true, message: '请输入', trigger: 'blur' }],
        passwd: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dialogVisible: false,
      tableData: [],
      total: 0,
      size: 20,
      current: 1,
      formInline: {
        storeNo: '',
        phone: '',
        nickname: ''
      }
    }
  },
  watch: {
    dialogVisible(newVal) {
      if (!newVal) {
        this.ruleForm = {
          account: '',
          passwd: '',
          phone: '',
          avatar: '',
          nickname: '',
          wechatNo: '',
          wechatImg: ''
        }
      }
    }
  },
  created() {
    this.getSubStorePage()
  },
  methods: {
    goDetails(row) {
      this.$router.push(`/store/fundDetails?id=${row.id}`)
    },
    edit(row) {
      this.ruleForm = {
        id: row.id,
        account: row.account,
        passwd: row.passwd,
        phone: row.phone,
        avatar: row.avatar,
        nickname: row.nickname,
        wechatNo: row.wechatNo,
        wechatImg: row.wechatImg
      }
      this.dialogVisible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            const data = await updateSubStore(this.ruleForm)
            this.dialogVisible = false
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.getSubStorePage()
          } else {
            const data = await insertSubStore(this.ruleForm)
            this.dialogVisible = false
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.getSubStorePage()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async beforeAvatarUpload(file1) {
      const { data } = await file({
        fileName: file1.name
      })
      data.body.file = file1
      const formData = new FormData()
      for (const key in data.body) {
        formData.append(key, data.body[key])
      }
      axios({
        method: 'post',
        url: data.post,
        data: formData
      }).then((res) => {
        this.ruleForm.wechatImg = data.url
      })
      return false
    },
    async beforeAvatarUpload1(file1) {
      const { data } = await file({
        fileName: file1.name
      })
      data.body.file = file1
      const formData = new FormData()
      for (const key in data.body) {
        formData.append(key, data.body[key])
      }
      axios({
        method: 'post',
        url: data.post,
        data: formData
      }).then((res) => {
        this.ruleForm.avatar = data.url
      })
      return false
    },
    search() {
      this.current = 1
      this.getSubStorePage()
    },
    async getSubStorePage() {
      const { data } = await getSubStorePage({
        pageSize: this.size,
        pageNum: this.current,
        storeNo: this.formInline.storeNo,
        phone: this.formInline.phone,
        nickname: this.formInline.nickname
      })
      this.tableData = data.list
      this.total = data.total * 1
    },
    handleSizeChange(val) {
      this.size = val
      this.getSubStorePage()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getSubStorePage()
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.label {
  background-color: #f2f2f2;
  line-height: 50px;
  border-left: 5px solid #0088ff;
  padding-left: 20px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  span {
    margin: 0 20px;
  }
}
.fy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
